console.group("inicializando o pack css das gems do site")

  console.log("inicializando componentes da uxkit")
  
  import '/var/www/apps/azr/engines/ux_kit19/app/frontend/util/css/components/user.scss';
  import '/var/www/apps/azr/engines/ux_kit19/app/frontend/vendor/css/owl/owl.scss';


  console.log("inicializando componentes da navkit")
  
  import '/var/www/apps/azr/engines/navkit/app/frontend/css/init.js';

console.groupEnd();
